import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full rounded-md overflow-hidden flex justify-center items-center" }
const _hoisted_2 = ["src", "alt"]

import { useSettings } from '@/store/store';
	import { computed, ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'HubLogo',
  props: {
    hubUrl: {},
    hubId: {},
    changeToDark: { type: Boolean }
  },
  setup(__props: any) {

	const settings = useSettings();

	const props = __props;
	let fallback = ref(true);

	const logoPath = computed(() => {
		if (settings.getActiveTheme === 'dark' && props.changeToDark) {
			return '/img/logo-dark.svg';
		} else {
			return '/img/logo.svg';
		}
	});

	function hideFallback() {
		fallback.value = false;
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("img", {
      class: "h-full w-full object-cover",
      src: _ctx.hubUrl + logoPath.value,
      onLoad: hideFallback,
      alt: 'logo of ' + _ctx.hubId
    }, null, 40, _hoisted_2), [
      [_vShow, !_unref(fallback)]
    ]),
    (_unref(fallback))
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          type: "hub_fallback"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})