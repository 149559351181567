import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between h-full" }
const _hoisted_2 = { class: "dark:bg-ph-background-5 bg-ph-background-4 h-24 grid items-center justify-center" }
const _hoisted_3 = { class: "w-14 h-14 flex justify-center items-center bg-white text-white hover:text-lightgray-light hover:bg-lightgray-light rounded-xl" }
const _hoisted_4 = { class: "flex-1 h-full w-full mx-auto overflow-hidden" }
const _hoisted_5 = { class: "grid gap-4 p-2 pb-4 h-fit" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "grid grid-cols-2 gap-2 w-fit mx-auto" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = ["href"]

import { useDialog, useGlobal } from '@/store/store';
	import { useToggleMenu } from '@/store/toggleGlobalMenu';
	import { ref } from 'vue';
	import { useI18n } from 'vue-i18n';

	// Components
	import DialogBubble from '../../../../hub-client/src/components/ui/DialogBubble.vue';
	import Logo from '../../../../hub-client/src/components/ui/Logo.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalBar',
  setup(__props) {

	const dialog = useDialog();
	const settingsDialog = ref(false);
	const { t } = useI18n();
	const global = useGlobal();
	const toggleMenu = useToggleMenu();
	const hubOrdering = ref(false);

	// eslint-disable-next-line
	const pubHubsUrl = _env.PUBHUBS_URL;

	async function logout() {
		if (await dialog.yesno(t('logout.logout_sure'))) {
			global.logout();
		}
	}

	function toggleHubOrdering() {
		hubOrdering.value = !hubOrdering.value;
	}

	function showHelp() {
		dialog.okcancel('Help?', 'Rescue in progress');
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HubMenu = _resolveComponent("HubMenu")!
  const _component_SettingsDialog = _resolveComponent("SettingsDialog")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", {
    id: "pubhubs-bar",
    class: _normalizeClass(["flex-none w-24 bg-ph-background-3 dark:bg-gray-darker h-full pt-20 2md:pt-0 2md:block", { hidden: !_unref(toggleMenu).globalIsActive }])
  }, [
    _createVNode(_component_Modal, {
      show: _unref(global).isModalVisible
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_Icon, {
                    type: "pubhubs-home",
                    size: "xl",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleMenu).hideMenuAndSendToHub()))
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_HubMenu, { hubOrderingIsActive: hubOrdering.value }, null, 8, ["hubOrderingIsActive"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_unref(global).loggedIn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (settingsDialog.value)
                    ? (_openBlock(), _createBlock(_component_SettingsDialog, {
                        key: 0,
                        onClose: _cache[1] || (_cache[1] = ($event: any) => (settingsDialog.value = false))
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_Icon, {
                      type: "question_mark",
                      class: "p-1 rounded-sm dark:bg-gray dark:hover:bg-gray-dark bg-gray-lighter hover:bg-gray-light hover:cursor-pointer",
                      size: "base",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (showHelp()))
                    }),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Icon, {
                        type: "reorder_hubs",
                        class: _normalizeClass([{ 'bg-white dark:hover:bg-lightgray-light text-black': hubOrdering.value }, "p-1 rounded-sm dark:bg-gray bg-gray-lighter hover:bg-white dark:hover:bg-lightgray-light hover:text-black hover:cursor-pointer"]),
                        size: "base",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (toggleHubOrdering()))
                      }, null, 8, ["class"]),
                      _createVNode(DialogBubble, {
                        class: "absolute -top-2/3 left-[150%]",
                        showBubble: hubOrdering.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('bubble.organize')), 1)
                        ]),
                        _: 1
                      }, 8, ["showBubble"])
                    ]),
                    _createVNode(_component_Icon, {
                      type: "cog",
                      class: "p-1 rounded-sm dark:bg-gray dark:hover:bg-gray-dark bg-gray-lighter hover:bg-gray-light hover:cursor-pointer",
                      size: "base",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (settingsDialog.value = true))
                    }),
                    _createVNode(_component_Icon, {
                      type: "power",
                      class: "p-1 rounded-sm dark:bg-gray dark:hover:bg-gray-dark bg-gray-lighter hover:bg-gray-light hover:cursor-pointer",
                      size: "base",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (logout()))
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("a", { href: _unref(pubHubsUrl) }, [
              _createVNode(Logo, { class: "px-1" })
            ], 8, _hoisted_9)
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 2))
}
}

})