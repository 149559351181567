import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col gap-1 text-center justify-between bg-ph-background-3 dark:bg-ph-background-5 hover:bg-blue h-36 p-4 rounded-md relative cursor-pointer w-full overflow-hidden"
}

import { computed } from 'vue';
	import { Hub } from '@/store/store';
	import HubIcon from '../../../../hub-client/src/components/shared-with-global-client/HubIcon.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'HubBlock',
  props: {
    hub: {}
  },
  setup(__props: any) {

	const props = __props;

	const description = computed(() => {
		if (props.hub.description !== '') {
			return props.hub.description;
		}
		return props.hub.hubId;
	});

return (_ctx: any,_cache: any) => {
  const _component_TruncatedText = _resolveComponent("TruncatedText")!
  const _component_H3 = _resolveComponent("H3")!

  return (_ctx.hub)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_H3, { class: "m-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_TruncatedText, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.hub.hubId), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(HubIcon, {
          "icon-url": _ctx.hub.iconUrlLight,
          "icon-url-dark": _ctx.hub.iconUrlDark,
          "hub-name": _ctx.hub.name,
          class: "max-h-16 max-w-16 mx-auto"
        }, null, 8, ["icon-url", "icon-url-dark", "hub-name"]),
        _createVNode(_component_TruncatedText, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(description.value), 1)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})