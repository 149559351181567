import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { Theme, useSettings } from '@/store/settings';
	import { computed } from 'vue';

	const logoLightUrl = '/client/img/logo.svg';
	const logoDarkUrl = '/client/img/logo-dark.svg';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Logo',
  setup(__props) {

	const settings = useSettings();

	const logoUrl = computed(() => {
		if (settings.getActiveTheme === Theme.Dark) {
			return logoDarkUrl;
		} else {
			return logoLightUrl;
		}
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    alt: "PubHubs logo",
    src: logoUrl.value
  }, null, 8, _hoisted_1))
}
}

})