<template>
	<div class="flex mb-4">
		<H1 class="text-xl xl:text-2xl font-normal theme-light:text-black mr-3 xl:mr-4">{{ $t('register.register_with') }}</H1>
		<Logo class="h-[1.125rem] xl:h-6"></Logo>
	</div>
</template>

<style scoped>
	H1 {
		font-family: 'work_sanssemibold';
	}
</style>
